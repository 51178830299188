import debug from "debug";
import ApiClient from "@/lib/ApiClient";
import { sureClone } from "@/lib/DataUtils";

import ModuleBase from "../common/ModuleBase";

const d = debug(`${import.meta.env.VITE_PREFIX}:AlertsStore`);

const DEFAULT_EDITOR = {
  recipients: null,
  message: null,
  isUnitAlert: true
};

export default ModuleBase({
  state: {
    editor: sureClone(DEFAULT_EDITOR)
  },

  getters: {},

  mutations: {
    newAlertToEditor(state) {
      state.editor = sureClone(DEFAULT_EDITOR);
    }
  },
  actions: {
    async createAlert({ state, commit }) {
      let alert = sureClone(state.editor);
      debug("createAlert", alert);
      let response = await ApiClient.post("/alerts/alert", alert);
      debug("createAlert - response", response);
      commit("newAlertToEditor");
      return alert;
    }
  }
});
