<template>
  <div class="app-toast">
    <v-snackbar
      justify="center"
      :model-value="show"
      @update:model-value="show = $event"
      :color="toast.type || 'info'"
      multi-line
      location="top"
      :timeout="timeout"
    >
      <div class="pa-4">
        <v-row>
          <span class="text">{{ toast.message }}</span>
          <v-spacer></v-spacer>
          <v-btn
            v-if="hasDetails"
            class="ml-4"
            :append-icon="showDetails ? 'mdi-chevron-up' : 'mdi-chevron-down'"
            variant="text"
            density="compact"
            @click="showDetailsHandler"
          >
            {{showDetails ? "Näytä vähemmän" : "Näytä enemmän"}}
          </v-btn>
         
        </v-row>

        <v-row v-if="hasDetails && showDetails">
          {{ toast.details }}
        </v-row>
      </div>
    </v-snackbar>
  </div>
</template>
<script>
import eventBus from "@/lib/eventBus";

export default {
  name: "ToastComponent",
  data() {
    return {
      show: false,
      showDetails: false,
      timeout: 6000,
      toast: {
        type: null,
        message: false,
        details: null
      }
    };
  },
  computed: {
    hasDetails() {
      return this.toast.details != null && this.toast.details != "";
    }
  },
  created() {
    const me = this;
    eventBus.off("show-toast");
    eventBus.on("show-toast", payload => {
      me.showToast(payload);
    });
  },

  methods: {
    showToast({ type, message, details, close = true }) {
      const me = this;
      me.toast.type = type;
      me.toast.message = message;
      me.toast.details = details;
      me.timeout = close ? 6000 : -1;
      me.show = true;
      me.showDetails = false;
    },
    showDetailsHandler() {
      const me = this;
      me.showDetails = !me.showDetails;
    }
  }
};
</script>
