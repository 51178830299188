import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: import.meta.env.VITE_APPLICATION_INSIGHTS_INSTR_KEY,
    enableAutoRouteTracking: true,
  },
});

appInsights.loadAppInsights();

export default appInsights;