<template>
  <ol-map style="height: 200px">
    <ol-view :center="position" :zoom="12" :projection="projection" ref="view" />
    <ol-tile-layer>
      <ol-source-osm />
    </ol-tile-layer>

    <ol-vector-layer>
      <ol-source-vector>
        <ol-feature>
          <ol-geom-point :coordinates="position"></ol-geom-point>
          <ol-style>
            <ol-style-icon :src="markerIcon" :scale="0.05"></ol-style-icon>
          </ol-style>
        </ol-feature>
        <ol-interaction-modify @modifyend="onFeatureMoved" />
      </ol-source-vector>
    </ol-vector-layer>
    
  </ol-map>
</template>
<style lang="sass" scoped></style>

<script setup lang="ts">  

import { type View } from "ol";
import { ref } from "vue";

import { fromLonLat, toLonLat } from 'ol/proj';

import markerIcon from "@/assets/location-pin.png";

const props = defineProps({
  coordinates: Array as () => [number, number],
  required: true
});

const emit = defineEmits(["update:coordinates"]);

const view = ref<View | null>(null);
const position = ref(fromLonLat(props.coordinates));
const projection = ref("EPSG:3857");

function onFeatureMoved(event) {
  event.features.forEach((feature) => {
    console.log("feature moved", feature.getGeometry().getCoordinates());
    
    emit("update:coordinates", toLonLat(feature.getGeometry().getCoordinates()));
  });
}

</script>
