import { ref } from "vue";

const dialog = ref(false);

export function useMissionDialog() {
  return {
    dialog,
    open: () => (dialog.value = true),
    close: () => (dialog.value = false),
  };
}