import ModuleBase from "../common/ModuleBase";

export default ModuleBase({
  state: {
    signalRState: "Unknown",
    isLoading: false,
    isLoggingOut: false
  },
  getters: {
    signalRState({ signalRState }) {
      return signalRState;
    },
    isLoading({ isLoading }) {
      return isLoading;
    },
    isLoggingOut({ isLoggingOut }) {
      return isLoggingOut;
    }
  },
  mutations: {},
  actions: {}
});
