//import Vue from "vue";
import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import LogoutCallback from "../views/LogoutCallback.vue";
import Alert from "../views/Alert.vue";
import ResourceTable from "../views/ResourceTable.vue";
import Settings from "../views/Settings.vue";
import UnitCreator from "../views/UnitCreator.vue";
import UnitEditor from "../views/UnitEditor.vue";
import MissionCreator from "../views/MissionCreator.vue";
import MissionEditor from "../views/MissionEditor.vue";
import StatusCreator from "../views/StatusCreator.vue";
import StatusEditor from "../views/StatusEditor.vue";
import NoteCreator from "../views/NoteCreator.vue";
import Units from "../views/Units.vue";
import Events from "../views/EventLog.vue";
import Situation from "../views/Situation.vue";
import MapComponent from "../views/MapComponent.vue";
import Missions from "../views/Missions.vue";
import { authGuard } from "../auth/authGuard";

//TODO CHECK Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/logout-callback",
    name: "LogoutCallback",
    component: LogoutCallback
  },
  {
    path: "/alert",
    name: "Alert",
    component: Alert,
    beforeEnter: authGuard
  },
  {
    path: "/missions",
    name: "Missions",
    component: Missions,
    beforeEnter: authGuard
  },
  {
    path: "/resource-table",
    name: "ResourceTable",
    component: ResourceTable,
    beforeEnter: authGuard
  },
  {
    path: "/situation",
    name: "Situation",
    component: Situation,
    beforeEnter: authGuard
  },
  {
    path: "/map",
    name: "Map",
    component: MapComponent,
    beforeEnter: authGuard
  },
  {
    path: "/units",
    name: "Units",
    component: Units,
    beforeEnter: authGuard
  },
  {
    path: "/events",
    name: "Event log",
    component: Events,
    beforeEnter: authGuard
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    beforeEnter: authGuard
  },
  {
    path: "/unit-creator",
    name: "UnitCreator",
    component: UnitCreator,
    beforeEnter: authGuard
  },
  {
    path: "/status-creator",
    name: "StatusCreator",
    component: StatusCreator,
    beforeEnter: authGuard
  },
  {
    path: "/mission-creator",
    name: "MissionCreator",
    component: MissionCreator,
    beforeEnter: authGuard
  },
  {
    path: "/note-creator",
    name: "NoteCreator",
    component: NoteCreator,
    beforeEnter: authGuard
  },
  {
    path: "/mission/:missionId",
    name: "MissionEditor",
    component: MissionEditor,
    props: true,
    beforeEnter: authGuard
  },
  {
    path: "/unit/:unitId",
    name: "UnitEditor",
    component: UnitEditor,
    props: true,
    beforeEnter: authGuard
  },
  {
    path: "/status/:statusId",
    name: "StatusEditor",
    component: StatusEditor,
    props: true,
    beforeEnter: authGuard
  }
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
});

export default router;
