import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import * as Sentry from "@sentry/vue";
import appInsights from './services/applicationInsights';
import GlobalMixin from "./mixins/GlobalMixin";
import VueDraggableResizable from "vue-draggable-resizable";

// The style are only needed for some map controls.
// However, you can also style them by your own
import "vue3-openlayers/styles.css";

import OpenLayersMap from "vue3-openlayers";

import "./mixins/GlobalMixin";

import { Auth0Plugin } from "./auth";

const app = createApp(App);

app.config.errorHandler = (err, vm, info) => {
  console.error(err); 
  appInsights.trackException({ error: err, properties: { info } }); 
};

app.component("vue-draggable-resizable", VueDraggableResizable);

app.mixin(GlobalMixin);

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT || "unknown",
  release: import.meta.env.VITE_APP_VERSION || 'unknown',
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  // Learn more at
  // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  // Learn more at
  // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

app.use(router);
app.use(store);
app.use(vuetify);

app.use(OpenLayersMap /*, options */);

app.use(Auth0Plugin, {
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
  audience: import.meta.env.VITE_AUTH0_AUDIENCE,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

app.mount("#app");
