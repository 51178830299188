<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useToast } from "@/composables/useToast";
import { useMissionDialog } from "@/composables/useNewMissionDialog"; 
import PositionOnMap from "../components/PositionOnMap.vue";

const store = useStore();
const toast = useToast();

const { dialog, close } = useMissionDialog();
const validForm = ref(false);
const formRef = ref(null); // Viite <v-form> elementtiin
const isLoading = ref(false);

const code = computed({
  get: () => store.state.missions.editor.code,
  set: (value) => store.commit("missions/set", ["editor.code", value]),
});

const address = computed({
  get: () => store.state.missions.editor.address,
  set: (value) => store.commit("missions/set", ["editor.address", value]),
});

const municipality = computed({
  get: () => store.state.missions.editor.municipality,
  set: (value) => store.commit("missions/set", ["editor.municipality", value]),
});

const details = computed({
  get: () => store.state.missions.editor.details,
  set: (value) => store.commit("missions/set", ["editor.details", value]),
});

const units = computed({
  get: () => store.state.missions.editor.units,
  set: (value) => store.commit("missions/set", ["editor.units", value])
});

const latitude = computed({
  get: () => store.state.missions.editor.latitude,
  set: (value) => store.commit("missions/set", ["editor.latitude", value])
});

const longitude = computed({
  get: () => store.state.missions.editor.longitude,
  set: (value) => store.commit("missions/set", ["editor.longitude", value])
});

const availableUnits = computed(() => store.getters["units/units"]);

const rules = {
  required: v => !!v || "Tämä kenttä on pakollinen",
};

function onCoordinatesUpdated(coordinates) {
  latitude.value = coordinates[1];
  longitude.value = coordinates[0];
}

async function save() {
  if (!formRef.value) return;

  const { valid } = await formRef.value.validate();
  if (!valid) return; // Estä tallennus, jos lomake ei ole validi

  try {
    isLoading.value = true;
    const mission = await store.dispatch("missions/createMission");
    toast.showToast({
      type: "success",
      message: `Uusi tehtävä '${mission.code} - ${mission.address}' on lisätty`
    });
  } catch (err) {
    if (err.message !== "cancel") {
      console.log("ERORRI TULI")
      toast.showError(err);
    }
  }
  isLoading.value = false;
  dialog.value = false;
}
</script>

<style scoped>
.dialog-title {
  background-color: #09656d;
  /* Sininen tausta */
  color: white;
  padding: 16px;
  display: flex;
  align-items: center;
  font-size: 18px;
  text-transform: uppercase;
}

.dialog-title v-icon {
  margin-right: 16px !important;
  padding-right: 16px;
}
</style>
<template>
    <v-dialog v-model="dialog" max-width="400px">
      <v-card>
        <v-card-title class="dialog-title">
          <v-icon style="margin-right: 8px;">mdi-bell-plus</v-icon>Uusi tehtävä</v-card-title>
        <v-card-text>
          <v-form ref="formRef" v-model="validForm">
            <v-select label="Yksiköt" v-model="units" multiple :items="availableUnits" item-title="name" item-value="id"
              placeholder="Valitse yksiköt" outlined></v-select>
            <v-text-field v-model="code" label="Tehtäväkoodi*" :rules="[rules.required]"></v-text-field>
            <v-text-field v-model="address" label="Osoite*" :rules="[rules.required]"></v-text-field>
            <v-text-field v-model="municipality" label="Paikkakunta*" :rules="[rules.required]"></v-text-field>
            <v-text-field v-model="details" label="Lisätiedot" :rules="[]"></v-text-field>
            <PositionOnMap :coordinates="[longitude,latitude]" @update:coordinates="onCoordinatesUpdated"/>
          </v-form>
        </v-card-text>

        <v-card-text>
          <v-btn color="#09656d" class="text-none mb-4" size="large" block variant="flat" :disabled="!validForm"
            :loading="isLoading" @click="save">Tallenna</v-btn>

          <v-btn color="grey-lighten-3" class="text-none" size="large" block variant="flat"
            @click="dialog = false">Peruuta</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>
