<template>
  <span>{{ ago }}</span>
</template>
<script>
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; 
import relativeTime from "dayjs/plugin/relativeTime"; 
import "dayjs/locale/fi";

dayjs.extend(utc);
dayjs.extend(relativeTime);

export default {
  name: "FromNow",
  props: {
    date: String
  },
  data() {
    return {
      ago: ""
    };
  },
  computed: {},
  created() {
    const me = this;
    me.updateAgoString();
    setInterval(() => {
      me.updateAgoString();
    }, 5000);
  },
  methods: {
    updateAgoString() {
      const me = this;
      this.ago = dayjs.utc(this.date).locale("fi").fromNow();
    }
  }
};
</script>
