<template>
  <v-container
    fluid
    class="d-flex justify-center align-center"
    style="height: 100vh;"
  >
    <v-row>
      <v-col class="d-flex flex-column justify-center align-center">
        <v-img
          class="home-page-logo"
          alt="KRISTA logo"
          src="@/assets/krista_logo_pos.png"
          :width="400"
        />
        <v-btn
          v-if="!$auth.state.isAuthenticated && !$auth.state.loading"
          color="primary"
          large
          @click="login"
        >
          Kirjaudu palveluun
        </v-btn>
      </v-col>
    </v-row>
    <div class="version-footer">
        Versio {{ version }}
    </div>

  </v-container>
</template>
<style lang="sass" scoped>
.home-page-logo
  margin-bottom: 50px
.version-footer
  font-size: small
  color: grey
  position: fixed
  bottom: 0
  margin-bottom: 10px
</style>
<script>
export default {
  name: "HomeComponent",
  data: () => ({
    version: import.meta.env.VITE_APP_VERSION ||"0.0.0",
  }),
  methods: {
    // Log the user in
    login() {
      this.$store.commit("app/set", ["isLoggingOut", false]); // Maybe not needed, but...
      this.$auth.loginWithRedirect();
    }
  }
};
</script>
