import _ from "lodash";
import debug from "debug";
import ApiClient from "@/lib/ApiClient";

import ModuleBase from "../common/ModuleBase";

const d = debug(`${import.meta.env.VITE_PREFIX}:PositionsStore`);

export default ModuleBase({
  state: {
    unitPositions: {}
  },

  getters: {
    unitPositions({ unitPositions }) {
      let values = _.values(unitPositions);
      return values;
    }
  },

  mutations: {
    clearUnitPositions(state) {
      state.unitPositions = [];
    },
    updateUnitPosition(state, unitPosition) {
      const existingPositionId = Object.keys(state.unitPositions).find(
        posId => state.unitPositions[posId].unitId === unitPosition.unitId
      );
  
      if (existingPositionId) {
        // Jos yksikön positio löytyy, korvataan se uudella
        state.unitPositions[existingPositionId] = unitPosition;
      } else {
        // Jos yksiköllä ei ole vielä positiota, lisätään se uudella ID:llä
        state.unitPositions[unitPosition.id] = unitPosition;
      }
    }
  },
  actions: {
    async load({ commit }) {
      debug("load");
      let unitPositions = await ApiClient.get(`/units/latest-positions`);
      debug("load", unitPositions);
      commit("set", ["unitPositions", _.keyBy(unitPositions, "id")]);
    },
    async updateUnitPosition({ state }, unitPosition) {
      debug("updateUnitPosition", unitPosition);
      let result = await ApiClient.put(
        `/units/${unitPosition.unitId}/position`,
        unitPosition
      );
      debug("updateUnitPosition - result", result);
    },
  }
});
