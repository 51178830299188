import { reactive, readonly } from "vue";
import { createAuth0Client } from "@auth0/auth0-spa-js";

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

let instance;

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}) => {
  if (instance) return instance;

  const state = reactive({
    loading: true,
    isAuthenticated: false,
    user: {},
    auth0Client: null,
    popupOpen: false,
    error: null
  });

  instance = {
    state: readonly(state),
    /** Authenticates the user using a popup window */
    async loginWithPopup(o) {
      state.popupOpen = true;

      try {
        await state.auth0Client.loginWithPopup(o);
      } catch (e) {
        console.error(e);
      } finally {
        state.popupOpen = false;
      }

      state.user = await state.auth0Client.getUser();
      state.isAuthenticated = true;
    },
    /** Handles the callback when logging in using a redirect */
    async handleRedirectCallback() {
      state.loading = true;
      try {
        await state.auth0Client.handleRedirectCallback();
        state.user = await state.auth0Client.getUser();
        state.isAuthenticated = true;
      } catch (e) {
        state.error = e;
      } finally {
        state.loading = false;
      }
    },
    /** Authenticates the user using the redirect method */
    loginWithRedirect(o) {
      return state.auth0Client.loginWithRedirect(o);
    },
    /** Returns all the claims present in the ID token */
    getIdTokenClaims(o) {
      return state.auth0Client.getIdTokenClaims(o);
    },
    /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
    getTokenSilently(o) {
      return state.auth0Client.getTokenSilently(o);
    },
    /** Gets the access token using a popup window */

    getTokenWithPopup(o) {
      return state.auth0Client.getTokenWithPopup(o);
    },
    /** Logs the user out and removes their session on the authorization server */
    logout(o) {
      return state.auth0Client.logout({ logoutParams: o });
    }
  };

  /** Use this lifecycle method to instantiate the SDK client */
  (async () => {
    // Create a new instance of the SDK client using members of the given options object
    state.auth0Client = await createAuth0Client({
      domain: options.domain,
      clientId: options.clientId,
      audience: options.audience,
      authorizationParams: {
        redirect_uri: redirectUri
      },
      useFormData: false
    });

    try {
      // If the user is returning to the app after authentication..
      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        // handle the redirect and retrieve tokens
        const { appState } = await state.auth0Client.handleRedirectCallback();
        // Notify subscribers that the redirect callback has happened, passing the appState
        // (useful for retrieving any pre-authentication state)
        onRedirectCallback(appState);
      }
    } catch (e) {
      state.error = e;
    } finally {
      // Initialize our internal authentication state
      state.isAuthenticated = await state.auth0Client.isAuthenticated();
      state.user = await state.auth0Client.getUser();
      state.loading = false;
    }
  })();

  return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install(app, options) {
    app.config.globalProperties.$auth = useAuth0(options);
  }
};
