<template>
  <div>
    <h2>Kirjaudutaan ulos...</h2>
  </div>
</template>
<style lang="sass" scoped></style>
<script>
export default {
  name: "LogoutCallback",
  mounted() {
    this.$store.commit("app/set", ["isLoggingOut", false]);
    this.$router.push("/");
  }
};
</script>
