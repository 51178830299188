<template>
  <ol-map style="height: calc(100vh - 64px);">
    <ol-view :center="center" :zoom="12" :projection="projection" ref="view" />
    <ol-tile-layer>
      <ol-source-osm />
    </ol-tile-layer>

    <ol-context-menu-control :defaultItems="false" :width="170" @open="onContextMenuOpen" ref="contextMenuRef" />

    <ol-vector-layer>
      <ol-source-vector>
        <ol-feature v-for="(unitWithPos, index) in unitsWithPositions" :key="index"
          :properties="{ unitId: unitWithPos.id }">
          <ol-geom-point
            :coordinates="fromLonLat([unitWithPos.latestPosition.longitude, unitWithPos.latestPosition.latitude])"></ol-geom-point>
          <ol-style>
            <ol-style-circle :radius="10">
              <ol-style-fill :color="unitWithPos.status?.colorBackground"></ol-style-fill>
              <ol-style-stroke color="#000000" :width="2"></ol-style-stroke>
            </ol-style-circle>
            <ol-style-text :text="unitWithPos.name" :offset-y="-20" font="bold 14px Arial" fill-color="black"
              stroke-color="white" stroke-width="2" />
          </ol-style>
        </ol-feature>
        <ol-interaction-modify @modifyend="onFeatureMoved" />
      </ol-source-vector>
    </ol-vector-layer>

    <ol-vector-layer>
      <ol-source-vector>
        <ol-feature v-for="(mission, index) in missionsWithPositions" :key="index"
          :properties="{ missionId: mission.id }">
          <ol-geom-point :coordinates="fromLonLat([mission.longitude, mission.latitude])"></ol-geom-point>
          <ol-style>
            <ol-style-icon :src="markerIcon" :scale="0.05"></ol-style-icon>
            <ol-style-text :text="missions.code" :offset-y="-20" font="bold 14px Arial" fill-color="black"
              stroke-color="white" stroke-width="2" />
            <ol-style-text :text="'Tehtävä ' + mission.code + ' - ' + mission.address" :offset-y="-20"
              font="bold 14px Arial" fill-color="black" stroke-color="white" stroke-width="2" />
          </ol-style>
        </ol-feature>
        <ol-interaction-modify @modifyend="onFeatureMoved" />
      </ol-source-vector>
    </ol-vector-layer>

  </ol-map>
</template>
<style lang="sass" scoped>
:deep(.context-menu-item)
  padding: 5px
</style>
<script setup lang="ts">

import { type View } from "ol";
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useMissionDialog } from "@/composables/useNewMissionDialog";
import type ContextMenu from "ol-contextmenu";

import { fromLonLat, toLonLat } from 'ol/proj';

import markerIcon from "@/assets/location-pin.png";
import { type Map } from "ol";

const view = ref<View | null>(null);
const store = useStore();

const contextMenuRef = ref<{ control: ContextMenu } | null>(null);

const units = computed(() => store.getters["units/units"]);
const positions = computed(() => store.getters["positions/unitPositions"]);
const missions = computed(() => store.getters["missions/missions"]);
const statuses = computed(() => store.getters["status/status"]);

const newMissionDialog = useMissionDialog();

const allUnits = computed(() => store.getters["units/units"]);
const unitsWithPositions = computed(() => {
  return units.value
    .map(unit => ({
      ...unit,
      latestPosition: positions.value.find(pos => pos.unitId === unit.id) || null // Hakee position id:n perusteella
    }))
    .filter(unit => unit.latestPosition !== null);
});

const missionsWithPositions = computed(() => {
  return missions.value.filter(missions => missions.latitude !== null && missions.longitude !== null);
});

const center = ref(fromLonLat([26.940977, 60.464080]));
const projection = ref("EPSG:3857");

onMounted(async () => {
  store.dispatch("missions/load");
  store.dispatch("units/load");
  store.dispatch("positions/load");
});

async function onFeatureMoved(event) {
  const features = event.features?.getArray() || [];

  if (features.length === 0) {
    return;
  }

  for (const feature of features) {
    const newCoords = toLonLat(feature.getGeometry().getCoordinates());
    const unitId = feature.getProperties().unitId;
    const missionId = feature.getProperties().missionId;

    if (unitId) {
      await store.dispatch("positions/updateUnitPosition", {
        unitId: unitId,
        latitude: newCoords[1],
        longitude: newCoords[0],
        timestamp: new Date().toISOString()
      });
    }

    if (missionId) {
      await store.dispatch("missions/loadMissionToEditor", missionId);
      store.commit("missions/set", ["editor.latitude", newCoords[1]]);
      store.commit("missions/set", ["editor.longitude", newCoords[0]]);
      await store.dispatch("missions/saveMissionInEditor");
    }
  }
}

function onContextMenuOpen(event) {
  contextMenuRef.value?.control?.clear();

  const missionsInClickPosition = [];
  const unitsInClickPosition = [];

  event.map.forEachFeatureAtPixel(event.pixel, (feature) => {
    const unitId = feature.get("unitId");
    if (unitId) {
      unitsInClickPosition.push(units.value.find(unit => unit.id === unitId))
    }
    const missionId = feature.get("missionId");
    if (missionId) {
      missionsInClickPosition.push(missions.value.find(mission => mission.id === missionId));
    }
  });

  if (missionsInClickPosition.length > 0) {
    missionsInClickPosition.forEach(mission => {
      contextMenuRef.value?.control?.push({
        text: mission.code + " - " + mission.address,
        classname: "context-menu-item",
        items: [
          {
            text: "Poista tehtävä",
            classname: "context-menu-item",
            callback: (val) => {
              store.commit("missions/deleteMission", mission.id);
            },
          }
        ]
      })
    });
    contextMenuRef.value?.control?.push("-");
  }

  if (unitsInClickPosition.length > 0) {
    unitsInClickPosition.forEach(unit => {
      contextMenuRef.value?.control?.push({
        text: unit.name,
        classname: "context-menu-item", // add some CSS rules
        items: statuses.value?.map(status => {
          return {
            text: status.name,
            classname: "context-menu-item", // add some CSS rules
            callback: async (val) => {
              const newUnitStatus = { ...unit, status: status };
              await store.dispatch("units/changeStatus", newUnitStatus);
            }
          }
        })
      });
    });
    contextMenuRef.value?.control?.push("-");
  }

  contextMenuRef.value?.control?.push(
    {
      text: "Uusi tehtävä tähän",
      classname: "context-menu-item",
      callback: async ({ coordinate }) => {
        const clickedLonLat = toLonLat(coordinate);
        await store.commit("missions/newMissionToEditor");
        store.commit("missions/set", ["editor.latitude", clickedLonLat[1]])
        store.commit("missions/set", ["editor.longitude", clickedLonLat[0]])
        newMissionDialog.open();
      }
    });

  contextMenuRef.value?.control?.push({
    text: "Keskitä kartta tähän",
    classname: "context-menu-item",
    callback: (val) => {
      view.value?.setCenter(val.coordinate);
    }
  });

  contextMenuRef.value?.control?.push({
    text: "Sijoita yksikkö tähän",
    classname: "context-menu-item",
    items: allUnits.value.map(unit => (
      {
        text: unit.name,
        callback: ({ coordinate }) => {
          const clickedLonLat = toLonLat(coordinate);
          store.dispatch("positions/updateUnitPosition", {
            unitId: unit.id,
            latitude: clickedLonLat[1],
            longitude: clickedLonLat[0],
            timestamp: new Date().toISOString()
          });
        }
      }
    )
    )
  });
}

</script>
