import _ from "lodash";
import debug from "debug";
import ApiClient from "@/lib/ApiClient";
import { objectDiff, sureClone } from "@/lib/DataUtils";
import ModuleBase from "../common/ModuleBase";
import { set, del } from "@/utils/stateUtils";

const d = debug(`${import.meta.env.VITE_PREFIX}:UnitsStore`);

const UNIT_TYPE_VIRVE = 1;
const UNIT_TYPE_VIRTUAL = 2;

const DEFAULT_EDITOR = {
  id: null,
  name: null,
  description: null,
  identifier: null,
  type: UNIT_TYPE_VIRTUAL
};

export default ModuleBase({
  state: {
    units: {},
    editor: sureClone(DEFAULT_EDITOR)
  },

  getters: {
    units({ units }) {
      let values = _.orderBy(_.values(units), ["name"], ["asc"]);
      return values;
    },
    alarmableUnits({ units }) {
      let values = _.orderBy(_.values(units), ["name"], ["asc"]).filter(
        u => u.type == UNIT_TYPE_VIRVE
      );
      return values;
    },
    editorChanges(state) {
      const unitId = _.get(state, "editor.id");
      if (!unitId) {
        return null;
      }
      const original = _.get(state.units, unitId);
      if (!original) {
        return null;
      }
      return objectDiff(original, state.editor);
    },
    editorHasChanges(state, getters) {
      let diff = getters.editorChanges;
      return diff && !_.isEmpty(diff);
    }
  },

  mutations: {
    clearUnits(state) {
      state.units = [];
    },
    newUnitToEditor(state) {
      state.editor = sureClone(DEFAULT_EDITOR);
    },
    addUnit(state, unit) {
      set(state.units, unit.id, unit);
    },
    deleteUnit(state, unitId) {
      del(state.units, unitId);
    }
  },
  actions: {
    async load({ commit }) {
      debug("load");
      let units = await ApiClient.get(`/units`);
      debug("server returned units: ", units);

      await Promise.all(
        units.map(async (unit) => {
          let status = await ApiClient.get(`/units/${unit.id}/status`);
          let unitWithStatus = { ...unit, status: status.status, statusReceived: status.receivedTs };
          commit("addUnit", unitWithStatus);
        })
      );
    },
    async loadUnitToEditor({ state, commit }, unitId) {
      debug("loadUnitToEditor", unitId);
      const unit = _.get(state.units, unitId);
      if (!unit) {
        throw new Error("Unit not found");
      }
      debug("unitToEditor", unit);
      commit("set", ["editor", sureClone(unit)]);
    },
    async saveUnitInEditor({ state, getters /*commit*/ }) {
      let unitId = state.editor.id;
      if (!getters.editorHasChanges) {
        throw new Error("No changes to save");
      }
      let modifiedUnit = sureClone(state.editor);
      debug("saveUnitInEditor", unitId, modifiedUnit);
      let result = await ApiClient.put(`/units/${unitId}`, modifiedUnit);
      debug("saveUnitInEditor, result", result);
    },
    async createUnit({ state, commit }) {
      let unit = sureClone(state.editor);
      _.unset(unit, "id");
      debug("createUnit", unit);
      let response = await ApiClient.post("/units", unit);
      debug("createUnit - response", response);
      commit("addUnit", response);
      return response;
    },
    async deleteUnit({ commit }, unitId) {
      debug("deleteUnit", unitId);
      let result = await ApiClient.delete(`/units/${unitId}`);
      debug("deleteUnit - result", result);
      commit("deleteUnit", unitId);
    },
    async changeStatus({ state }, unitStatus) {
      debug(state.units);
      debug("changeStatus", unitStatus);
      let result = await ApiClient.put(
        `/units/${unitStatus.id}/status`,
        unitStatus.status
      );
      debug("changeStatus - result", result);
      //TODO: CHeck if update of local data needed
      //commit("deleteUnit", unitId);
    },
    async updateStatus({ rootState, state, commit }, statusUpdate) {
      console.log("updateStatus", statusUpdate);
      let unitWithStatus = state.units[statusUpdate.unit];
      let status = rootState.status.status[statusUpdate.status];
      unitWithStatus.statusReceived = statusUpdate.receivedTs;
      set(unitWithStatus, "status", status);
      //TODO: Some better way?
      commit("deleteUnit", unitWithStatus.id);
      commit("addUnit", unitWithStatus);
    }
  }
});
