<template>
  <v-card>
    <v-toolbar density="compact" color="primary">
      <v-toolbar-title>
        <v-icon size="small" class="mr-2">mdi-history</v-icon>
        Aikajana
      </v-toolbar-title>
      <v-spacer v-if="showToolbarSpacer"></v-spacer>
      <v-text-field
        style="max-width: 200px"
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        single-line
        hide-details
        density="compact"
      ></v-text-field>
    </v-toolbar>

    <v-data-table
      :items="events"
      :headers="headers"
      item-key="id"
      density="compact"
      :search="search"
      :loading="isLoading"
    >
      <template v-slot:top.data-table>
        <v-toolbar flat v-if="!noTitle">
          <v-toolbar-title
            class="d-flex text-primary text-subtitle-2 text-uppercase"
            >Aikajana</v-toolbar-title
          >
        </v-toolbar>
      </template>

      <template v-slot:item.description="{ item }">
        <v-icon v-if="isNote(item)" small class="mr-2"
          >mdi-note-text-outline</v-icon
        >
        <v-icon v-if="isStatusUpdate(item)" small class="mr-2"
          >mdi-resistor</v-icon
        >
        {{ item.description }}
      </template>

      <template v-slot:item.datetime="{ item }">
        {{ formattedDateTime(item.dateTime) }}
      </template>

      <template v-slot:header.description="{}">
        Tapahtuma
        <v-menu
          bottom
          right
          :close-on-content-click="false"
          transition="scale-transition"
          origin="top left"
        >
          <template v-slot:activator="{ props }">
            <v-icon size="small" v-bind="props">mdi-filter-menu</v-icon>
          </template>
          <v-card width="300">
            <v-list density="compact" nav>
              <v-list-item>
                <v-checkbox
                  v-model="showNotes"
                  label="Näytä muistiinpanot"
                  color="primary"
                  density="compact"
                  hide-details="auto"
                ></v-checkbox>
              </v-list-item>

              <v-list-item>
                <v-checkbox
                  v-model="showStatus"
                  label="Näytä tilatiedot"
                  color="primary"
                  density="compact"
                  hide-details="auto"
                ></v-checkbox>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </template>
    </v-data-table>
  </v-card>
</template>
<style lang="sass" scoped></style>
<script>
import moment from "moment";

export default {
  props: {
    isLoading: Boolean,
    noTitle: Boolean
  },
  data: () => ({
    headers: [
      { title: "Aika", value: "datetime", sortable: false },
      { title: "Yksikkö", value: "unit", sortable: false },
      { title: "Tapahtuma", value: "description", sortable: false }
    ],
    search: "",
    showToolbarSpacer: true
  }),
  computed: {
    events() {
      const me = this;
      return me.$store.getters["events/events"].filter(item =>
        me.isNote(item)
          ? me.showNotes
          : me.isStatusUpdate(item)
          ? me.showStatus
          : true
      );
    },
    showNotes: {
      get() {
        return this.$store.getters["localStorage/showNotes"];
      },
      set(value) {
        this.$store.commit("localStorage/set", ["showNotes", value]);
      }
    },
    showStatus: {
      get() {
        return this.$store.getters["localStorage/showStatus"];
      },
      set(value) {
        this.$store.commit("localStorage/set", ["showStatus", value]);
      }
    }
  },
  methods: {
    isNote(item) {
      return item.event === "Muistiinpano";
    },
    isStatusUpdate(item) {
      return item.event === "Tilatieto muuttunut";
    },
    formattedDateTime(value) {
      return moment
        .utc(value)
        .local()
        .format("D.M. HH:mm");
    },
    formattedTime(value) {
      return moment
        .utc(value)
        .local()
        .format("HH:mm");
    }
  }
};
</script>
