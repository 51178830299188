import eventBus from "@/lib/eventBus";
import _ from "lodash";

export function useToast() {
  function showToast({ type = "error", message = null, details = null, close = true }) {
    eventBus.emit("show-toast", {
      type,
      message,
      details,
      close
    });
  }

  function showError(err) {
    if (_.isString(err)) {
      showToast({
        type: "error",
        message: err,
        close: false
      });
    } else if (err && err.response && err.response.status) {
      showToast({
        type: "error",
        message: `Palvelinpyyntö epäonnistui virhekoodilla ${err.response.status}`,
        details: `Palvelin palautti: ${JSON.stringify(err.response.data)}`,
        close: false
      });
    } else if (err && err.message) {
      showToast({
        type: "error",
        message: err.message,
        close: false
      });
    }
  };
  
  return {
    showToast,
    showError
  };
}