<template>
  <v-container>
    <v-row justify="center">
      <v-col lg="8">
        <UnitListWidget :isLoading="isLoading" />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col lg="8">
        <StatusListWidget />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col lg="8">
        <div class="version-info">Käyttöliittymän versio {{version}}</div>
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="sass" scoped>
.version-info
  font-size: small
  color: grey
  
  margin-top: 10px
</style>

<script>
// @ is an alias to /src
import StatusListWidget from "@/components/StatusListWidget.vue";
import UnitListWidget from "@/components/UnitListWidget.vue";

export default {
  name: "SettingsComponent",
  components: {
    StatusListWidget,
    UnitListWidget
  },
  data: () => ({
    isLoading: false,
    version: import.meta.env.VITE_APP_VERSION
  }),
  async mounted() {}
};
</script>
