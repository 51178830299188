<template>
  <base-dialog
    v-model="dialog"
    title="Uusi muistiinpano"
    icon="mdi-pencil-plus"
    :valid-form="validForm"
    :is-loading="isLoading"
    @cancel="handleCancel"
    @save="createNote"
  >
    <template #content>
      <v-form v-model="validForm">
        <v-row>
          <v-col cols="12">
            <v-select
              label="Yksiköt (valinnainen)"
              v-model="units"
              multiple
              :items="availableUnits"
              item-title="name"
              item-value="name"
              placeholder="Valitse yksiköt"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="text"
              label="Teksti"
              placeholder="Kirjoita muistiinpano tähän..."
              outlined
              required
              :rules="$rules.required"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
      <small class="text-caption text-medium-emphasis"
        >*pakollinen kenttä</small
      >
    </template>
  </base-dialog>

  <v-btn color="white" small variant="outlined" class="mr-3" @click="newNote">
    <v-icon size="small" class="mr-1">mdi-pencil-plus</v-icon>
    Uusi muistiinpano
  </v-btn>
</template>

<style lang="sass" scoped></style>
<script>
import BaseDialog from "./BaseDialog.vue";
import FormMixin from "@/mixins/FormMixin";

export default {
  mixins: [FormMixin],
  components: { BaseDialog },
  props: {
    inModal: Boolean
  },
  data: () => ({
    validForm: false,
    dialog: false,
    isLoading: false
  }),
  computed: {
    id: {
      get() {
        return this.$store.state.events.editor.id;
      },
      set(value) {
        this.$store.commit("events/set", ["editor.id", value]);
      }
    },
    text: {
      get() {
        return this.$store.state.events.editor.description;
      },
      set(value) {
        this.$store.commit("events/set", ["editor.description", value]);
      }
    },
    units: {
      get() {
        return this.$store.state.events.editor.unit;
      },
      set(value) {
        this.$store.commit("events/set", ["editor.unit", value]);
      }
    },
    availableUnits: {
      get() {
        return this.$store.getters["units/units"];
      }
    }
  },
  watch: {
    validForm(value) {
      this.$emit("is-valid", value);
    }
  },
  methods: {
    async newNote() {
      const me = this;
      await me.$store.commit("events/newEventToEditor", me.$store.state);
      me.dialog = true;
    },
    async createNote() {
      const me = this;
      try {
        me.isLoading = true;
        await me.$store.dispatch("events/createEvent");
        me.$toast({
          type: "success",
          message: `Muistiinpano on tallennettu`
        });
        me.dialog = false;
        me.isLoading = false;
      } catch (err) {
        if (err.message !== "cancel") {
          me.$error(err);
        }
        me.isLoading = false;
      }
    },
    handleCancel() {
      this.dialog = false;
    }
  }
};
</script>
