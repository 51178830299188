 

import _ from "lodash";
import eventBus from "@/lib/eventBus";
import { createApp } from "vue";

const globalEventBus = createApp().config.globalProperties;

export default {
  computed: {
    $bus() {
      console.log("glob", globalEventBus);
      return globalEventBus;
    },

    $currentUser() {
      return this.$store.state.user;
    }
  },

  methods: {
    /**
     * Programmatically navigate to route. If object then Vue Router object
     * https://router.vuejs.org/en/essentials/navigation.html
     * @param location string|object
     */
    async $redirect(location) {
      await this.$router.push(location);
    },

    $cleanObject(obj) {
      if (!obj) {
        return null;
      }
      return JSON.parse(JSON.stringify(obj));
    },

    $toast({ type = "error", message = null, details = null, close = true }) {
      eventBus.emit("show-toast", {
        type,
        message,
        details,
        close
      });
    },

    $confirm(
      message = null,
      title = null,
      textOkButton = "OK",
      textCancelButton = "Peruuta"
    ) {
      return new Promise((resolve, reject) => {
        eventBus.on("confirm.cancel", () => {
          reject(new Error("cancel"));
          eventBus.off(["confirm.cancel", "confirm.ok"]);
        });
        eventBus.on("confirm.ok", () => {
          resolve();
          eventBus.off(["confirm.cancel", "confirm.ok"]);
        });
        eventBus.emit("show-confirm", {
          title: title,
          message: message,
          textOkButton: textOkButton,
          textCancelButton: textCancelButton
        });
      });
    },

    $alert(message, timeout = 6000) {
      this.$toast({
        type: "warning",
        message: message,
        timeout: timeout
      });
    },

    $error(err) {
      let me = this;
      if (_.isString(err)) {
        me.$toast({
          type: "error",
          message: err
        });
      } else if (err && err.response && err.response.status) {
        me.$toast({
          type: "error",
          message: `Request failed with status code ${err.response.status}`,
          details: `Server returned: ${JSON.stringify(err.response.data)}`
        });
      } else if (err && err.message) {
        me.$toast({
          type: "error",
          message: err.message
        });
        console.error(err);
      }
    },

    $debug() {
      let me = this;
      let identifier = "unknown";
      if (me._name) {
        identifier = me._name;
      } else if (me.$options.__file) {
        identifier = me.$options.__file;
      }
      // TODO Debug(import.meta.env.VITE_PREFIX + ":" + identifier)(...arguments);
    },

    $timeout(ms) {
      return new Promise(res => setTimeout(res, ms));
    },

    $randomString(length = 6) {
      if (length < 1 || length > 12) {
        throw new Error(
          "$randomString only supports string lengths from 1 to 12"
        );
      }
      return Math.random()
        .toString(36)
        .slice(13 - length);
    },

    $capitalize(str) {
      return str ? str.charAt(0).toUpperCase() + str.slice(1) : null;
    }
  }
};
