<template>
  <div style="height: calc(100vh - 64px);">
    <splitpanes horizontal class="default-theme" @resized="verticalPaneResized">
      <pane :size="canvasHeight">
        <splitpanes class="default-theme" @resized="horisontalPaneResized">
          <pane :size="canvasWidth">
            <ColoredUnitCard v-for="unitStatus in unitStatuses" :key="unitStatus.id" :unitStatus="unitStatus" />
          </pane>
          <pane :size="100 - canvasWidth">
            <EventLogWidget class="event-log" />
          </pane>
        </splitpanes>
      </pane>
      <pane :size="100 - canvasHeight" style="background-color: transparent;">
        <MissionListWidget class="mission-list" :dataUpdated="playSound" />
      </pane>
    </splitpanes>
  </div>
</template>

<style lang="sass">
.unit-card 
  background: green
  height: 100px
  .unit-name 
    font-weight: bolder
    font-size: large
  
  .unit-description 
    font-size: small
    font-weight: normal
  
  .status-age 
    font-size: x-small
  
  .status-name .v-select__selection-text
    font-weight: bold
    font-size: smaller
    text-align: right
  
  .status-name .v-field__input
    justify-content: end

.event-log 
  height: 100%
  margin: 1px

.mission-list 
  height: 100%
  margin: 1px

.moveable-line 
  width: 0px !important
  height: 0px !important

</style>
<script>
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";

import EventLogWidget from "@/components/EventLogWidget.vue";
import MissionListWidget from "@/components/MissionListWidget.vue";
import ColoredUnitCard from "@/components/ColoredUnitCard.vue";
export default {
  name: "SituationComponent",
  components: {
    Splitpanes,
    Pane,
    EventLogWidget,
    ColoredUnitCard,
    MissionListWidget
  },
  data: () => ({
    isLoading: false
  }),
  computed: {
    unitStatuses() {
      return this.$store.getters["units/units"];
    },
    canvasHeight() {
      return this.$store.getters[
        "localStorage/situationCanvasPaneHeightPercent"
      ];
    },
    canvasWidth() {
      return this.$store.getters[
        "localStorage/situationCanvasPaneWidthPercent"
      ];
    }
  },
  methods: {
    verticalPaneResized(event) {
      this.$debug(event);
      this.$store.dispatch(
        "localStorage/saveSituationCanvasHeight",
        event[0].size
      );
    },
    horisontalPaneResized(event) {
      this.$debug(event);
      this.$store.dispatch(
        "localStorage/saveSituationCanvasWidth",
        event[0].size
      );
    },
    playSound() {
      const audio = new Audio(new URL('@/assets/170880__kevenmw__gnat-ringer2.mp3', import.meta.url).href);
      audio.play().catch(error => {
        console.error("Error playing the audio:", error);
      });
    }
  },
  async mounted() { }
};
</script>
