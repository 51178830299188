import _ from "lodash";
import debug from "debug";
import { set } from "@/utils/stateUtils";

import ModuleBase from "../common/ModuleBase";

const d = debug(`${import.meta.env.VITE_PREFIX}:LocalStorageStore`);

export default ModuleBase({
  state: {
    situationCardPositions: {},
    situationCanvasPaneHeightPercent: 50,
    situationCanvasPaneWidthPercent: 50,
    showNotes: true,
    showStatus: true
  },

  getters: {
    situationCardPositions({ situationCardPositions }) {
      return _.values(situationCardPositions);
    },
    situationCanvasPaneHeightPercent({ situationCanvasPaneHeightPercent }) {
      debug("getter", situationCanvasPaneHeightPercent);
      return situationCanvasPaneHeightPercent;
    },
    situationCanvasPaneWidthPercent({ situationCanvasPaneWidthPercent }) {
      debug("getter", situationCanvasPaneWidthPercent);
      return situationCanvasPaneWidthPercent;
    },
    showNotes({ showNotes }) {
      return showNotes;
    },
    showStatus({ showStatus }) {
      return showStatus;
    }
  },
  mutations: {
    saveSituationCardPosition(state, situationCardPosition) {
      set(
        state.situationCardPositions,
        situationCardPosition.unitId,
        situationCardPosition
      );
    }
  },
  actions: {
    async load({ commit }) {
      debug("load");

      let storedCardPosString = localStorage.getItem("situationCardPositions");
      let parsedPositions = JSON.parse(storedCardPosString);
      commit("set", [
        "situationCardPositions",
        _.keyBy(parsedPositions, "unitId")
      ]);

      let storedSituationCanvasPaneHeightPercentString = localStorage.getItem(
        "situationCanvasPaneHeightPercent"
      );
      let parsedHeight = parseInt(storedSituationCanvasPaneHeightPercentString);
      commit("set", ["situationCanvasPaneHeightPercent", parsedHeight]);

      let storedSituationCanvasPaneWidthPercentString = localStorage.getItem(
        "situationCanvasPaneWidthPercent"
      );
      let parsedWidth = parseInt(storedSituationCanvasPaneWidthPercentString);
      commit("set", ["situationCanvasPaneWidthPercent", parsedWidth]);
    },
    save({ commit, getters }, pos) {
      debug("save", pos);
      commit("saveSituationCardPosition", pos);
      let j = JSON.stringify(getters["situationCardPositions"]);
      localStorage.setItem("situationCardPositions", j);
    },
    saveSituationCanvasHeight({ commit }, height) {
      debug("save", height);
      commit("set", ["situationCanvasPaneHeightPercent", height]);
      localStorage.setItem("situationCanvasPaneHeightPercent", height);
    },
    saveSituationCanvasWidth({ commit }, width) {
      debug("save", width);
      commit("set", ["situationCanvasPaneWidthPercent", width]);
      localStorage.setItem("situationCanvasPaneWidthPercent", width);
    }
  }
});
